a.bouton{
    border-style: solid;
    border-width: 1px;
    /*letter-spacing: 4.7px;*/
    padding: 10px 25px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    font-family: "Open Sans";
}

a.bt-transparent-blanc{
    background: rgba(255, 255, 255, 0);
    border-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
}
a.bt-transparent-blanc:hover{
    background: rgb(255, 255, 255);
    border-color: rgb(4, 162, 201);
    color: rgb(4, 162, 201);
}

.uppercase{
    text-transform: uppercase;
}

.bt-blanc-bleu{
    background: white;
    border-color: rgb(4, 162, 201);
    color: rgb(4, 162, 201);
}
.bt-blanc-bleu:hover{
    border-color: black;
    color: black;
}



/* ---- FOOTER ------ */
footer{
    font-family: "Open Sans";
}

a{
    color: inherit;
}

.container{
    display: flex;
}
.text{
    color: white;
    letter-spacing: 0.3;
    padding: 0;
    text-align: center;
    font-family: "Open Sans";
}

.titre{
    font-weight: bold;
}

.footer_col2{
    display: flex;
    flex-direction: column;
    align-items: flex-start
}
.footer_col3{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.boutonConnexion{
    background: white;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    color: black;
    letter-spacing: 0.3px;
    padding: 10px 25px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    font-family: "Open Sans";
    font-size: 16px;
    width: auto;
}
.boutonConnexion:hover{
    background: black;
    color: white;
}

@media (min-width: 0px) {
    .container{
        flex-direction: column;
    }
    .titre {
      font-size: 35px; /* Taille de police pour les écrans moyens */
    }
    .paragraph{
        font-size: 16px;
    }
    /*.bouton{
        font-size: 14px;
        width: 100%;
        margin: 10px 0px;
    }*/
    .footer_col1{
        padding: 0px 0px 10px;
        width: 1000px;
    }
    .footer_col2{
        padding: 0px;
        width: 100%;
    }
    .footer_col3{
        padding: 20px 0px 0px 0%;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .container{
        flex-direction: row;
    }
    .titre {
      font-size: 40px; /* Taille de police pour les écrans moyens */
    }
    .paragraph{
        font-size: 16px;
    }
    /*.bouton{
        font-size: 14px;
        width: 100%;
        margin: 10px 0px;
    }*/
    .footer_col1{
        padding: 0px 20px 0px 0px;
        width: 173px;
    }
    .footer_col2{
        padding: 0px;
        width: 50%;
    }
    .footer_col3{
        padding: 0px 0px 0px 2%;
        width: 49%;
    }
}
  
@media (min-width: 992px) {
    .titre {
      font-size: 54px; /* Taille de police pour les grands écrans */
    }
    .paragraph{
        font-size: 19px;
    }
    /*.bouton{
        font-size: 19px;
        width: 295px;
        margin: 10px 25px;
    }*/
    .footer_col1{
        padding: 0px 20px 0px 0px;
        width: 277px;
    }
    .footer_col2{
        padding: 0px 10% 0px 0px;
        width: 65%;
    }
    .footer_col3{
        padding: 0px 0px 0px 5%;
        width: 35%;
    }
}